import Tilt from "react-parallax-tilt";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
/* eslint-disable-next-line no-unused-vars */
import tiltStyles from "./tiltStyles.css"; // don't comment this out, it IS being used even if it says it's not
import Image from "next/image";
import Link from "next/link";

const TiltCard = ({
  clickAction,
  link,
  video,
  image,
  text,
  // pulse,
  isSmall,
  disabled = false,
  disabledText = "Stop any running experiment first",
  customStyle,
  isPlay,
}) => {
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const shouldShrink = isSmall || isSmOrSmaller;
  // const isColumn = useMediaQuery(theme.breakpoints.down("md"));
  // const isColumnNotSmall = isColumn && !isSmall;

  return (
    <Link
      href={link || "#"}
      onClick={(e) => {
        if (disabled || !link) {
          e.preventDefault();
          if (!disabled && clickAction) clickAction();
        }
      }}
      title={disabled ? disabledText : null}
      style={{
        flex: 1,
        width: "100%",
        maxWidth: shouldShrink ? "300px" : "100%",
        // maxHeight: shouldShrink ? "100px" : "60vh",
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.5 : 1,
        color: "white",
        textDecoration: "none",
        ...(customStyle || {}),
      }}
    >
      {/* Optional pulsing light around card on load */}
      {/* <Box
        sx={{
          width: "100%",
          animation: pulse ? "pulse 2s ease-in-out 3" : null,
          "@keyframes pulse": pulse
            ? {
                "0%, 100%": {
                  boxShadow: `0 0 8px 0 ${theme.palette.primary.main}`,
                },
                "50%": {
                  boxShadow: "rgb(114 158 195 / 60%) 0px 0px 15px 5px",
                },
              }
            : null,
        }}
      > */}
      {/* Tilt effect */}
      <Tilt
        tiltMaxAngleX={disabled ? 0 : 10}
        tiltMaxAngleY={disabled ? 0 : 10}
        className="tilt-card"
        style={{
          margin: isPlay ? 0 : shouldShrink ? "10px 0" : "5px 0",
          padding: shouldShrink ? "10px 10px 5px 10px" : "10px 20px 10px 20px",
          width: "100%",
          ...(isPlay && { height: "100%" }),
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          alignItems: "center",
          border: "1px solid",
          borderRadius: "5px",
          // borderColor: "#24282d",
          borderColor: theme.palette.primary.dark,
          ...(shouldShrink && { backgroundColor: "#181b1eed" }),
          ...(isPlay && { backgroundColor: "black" }),
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: "#181b1eed",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {video && (
            <video
              crossOrigin="anonymous"
              muted={true}
              playsInline={true}
              autoPlay={true}
              loop={true}
              controls={false}
              preload="auto"
              style={{
                maxWidth: "250px",
                maxHeight: isSmOrSmaller ? "170px" : "100%",
                objectFit: "contain",
              }}
            >
              <source src={video} type="video/mp4" />
            </video>
          )}
          {image && (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: isPlay ? "95%" : shouldShrink ? "100px" : "260px",
              }}
            >
              <Image
                src={image}
                alt="background"
                fill
                sizes="(max-width: 768px) 100vw, 50vw"
                style={{
                  objectFit: "contain",
                }}
                priority={text === "Play"}
              />
            </Box>
          )}
        </Box>
        <Typography
          sx={{
            width: "100%",
            py: shouldShrink ? 1 : 3,
            textAlign: "center",
            fontSize: "clamp(1.2rem, 4dvw, 1.4rem)",
            fontWeight: "600",
            ...(isPlay && !disabled && { color: "primary.main" }),
            // bgcolor: "#101214bd",
          }}
        >
          {text}
        </Typography>
      </Tilt>
      {/* </Box> */}
    </Link>
  );
};

export default TiltCard;
